<template>
    <div class="privacy-policy" @scroll="checkPosition">
        <img class="logo" src="@/components/assets/logoPC.svg" alt="">
        <div class="privacy-policy-container" @scroll="checkPosition">
            <div class="privacy-policy-wrapper" @scroll="checkPosition">
                <h1 class="privacy-policy-header">Privacy Policy</h1>
                <p class="privacy-policy-last-update">Last updated: <span class="privacy-policy-date">{{
                    data.generalInfo.lastUpdate }}</span></p>
                <h2 class="privacy-policy-subscription">{{ data.generalInfo.describes }}</h2>
                <h2 class="privacy-policy-subscription">{{ data.generalInfo.weUse }}</h2>
                <div ref="firstElementRef">
                    <h1 class="privacy-policy-section-header">{{ data.firstSection.sectionName }}</h1>
                    <h3 class="privacy-policy-section-subheader">Interpretation</h3>
                    <p class="privacy-policy-default-text">{{ data.firstSection.interpretation }}</p>
                    <h3 class="privacy-policy-section-subheader">Definitions</h3>
                    <p class="privacy-policy-default-text">For the purposes of this Privacy Policy:</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.firstSection.definitions"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                </div>
                <div ref="secondElementRef">
                    <h1 class="privacy-policy-section-header">{{ data.secondSection.sectionName }}</h1>
                    <h3 class="privacy-policy-section-subheader">Types of Data Collected</h3>
                    <h3 class="privacy-policy-section-subheader normal-weight">Personal Data</h3>
                    <p class="privacy-policy-default-text">{{ data.secondSection.personalDataText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.secondSection.personalData"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <h3 class="privacy-policy-section-subheader normal-weight">Usage Data</h3>
                    <p v-for="(text, index) in data.secondSection.usageData" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader normal-weight">Tracking Technologies and Cookies </h3>
                    <p class="privacy-policy-default-text">{{ data.secondSection.trackingTechnologiesText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li"
                            v-for="(text, index) in data.secondSection.trackingTechnologies"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <p class="privacy-policy-default-text">{{ data.secondSection.cokies }}</p>
                    <p class="privacy-policy-default-text">{{ data.secondSection.weUseText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.secondSection.weUse"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <p class="privacy-policy-default-text">{{ data.secondSection.moreInformation }}</p>
                    <h3 class="privacy-policy-section-subheader">Use of Your Personal Data</h3>
                    <p class="privacy-policy-default-text">{{ data.secondSection.useOfPersonalDataText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li"
                            v-for="(text, index) in data.secondSection.useOfPersonalData"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <p class="privacy-policy-default-text">{{ data.secondSection.weMayShareText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.secondSection.weMayShare"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <h3 class="privacy-policy-section-subheader">Retention of Your Personal Data</h3>
                    <p v-for="(text, index) in data.secondSection.retentionDataText" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader">Transfer of Your Personal Data</h3>
                    <p v-for="(text, index) in data.secondSection.transferData" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader">Delete Your Personal Data</h3>
                    <p v-for="(text, index) in data.secondSection.deleteData" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                </div>
                <div ref="thirdElementRef">
                    <h1 class="privacy-policy-section-header">{{ data.thirdSection.sectionName }}</h1>
                    <h3 class="privacy-policy-section-subheader">Business Transactions </h3>
                    <p class="privacy-policy-default-text">{{ data.thirdSection.businessTransacrions }}</p>
                    <h3 class="privacy-policy-section-subheader">Law enforcement </h3>
                    <p class="privacy-policy-default-text">{{ data.thirdSection.law }}</p>
                    <h3 class="privacy-policy-section-subheader">Other legal requirements </h3>
                    <p class="privacy-policy-default-text">{{ data.thirdSection.otherLegalText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.thirdSection.otherLegal"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <h3 class="privacy-policy-section-subheader">Security of Your Personal Data </h3>
                    <p class="privacy-policy-default-text">{{ data.thirdSection.securityOfPersonalData }}</p>
                    <h3 class="privacy-policy-section-subheader">Children's Privacy</h3>
                    <p v-for="(text, index) in data.thirdSection.childPrivacy" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader">Links to Other Websites </h3>
                    <p v-for="(text, index) in data.thirdSection.linksToOtherWebsites" :key="index + Math.random() * 10000"
                        class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader">Changes to this Privacy Policy</h3>
                    <p v-for="(text, index) in data.thirdSection.changesToPrivacyPolicy"
                        :key="index + Math.random() * 10000" class="privacy-policy-default-text">{{ text }}</p>
                    <h3 class="privacy-policy-section-subheader">Contact Us </h3>
                    <p class="privacy-policy-default-text">{{ data.thirdSection.contactUsText }}</p>
                    <ul class="dot-container">
                        <li class="privacy-policy-default-text li" v-for="(text, index) in data.thirdSection.contactUs"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="privacy-policy-navigator-container">
                <div class="privacy-policy-navigator">
                    <h1 class="privacy-policy-section-header">Content</h1>
                    <div @click="move(1)" class="navigation-section-subheader-container">
                        <h3 class="navigation-section-subheader" :class="currentPosition >= 0 ? 'active-navigation' : ''">{{
                            data.firstSection.sectionName }}</h3>
                        <img v-if="currentPosition >= 0" src="@/components/assets/to-content-filled.svg" alt="">
                        <img v-else src="@/components/assets/to-content.svg" alt="">
                    </div>

                    <ul>
                        <li class="navigation-sections li" v-for="(text, index) in data.firstSection.sectionElements"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <div @click="move(2)" class="navigation-section-subheader-container">
                        <h3 class="navigation-section-subheader"
                            :class="currentPosition >= secondSectionPosition ? 'active-navigation' : ''">{{
                                data.secondSection.sectionName }}</h3>
                        <img v-if="currentPosition >= secondSectionPosition" src="@/components/assets/to-content-filled.svg"
                            alt="">
                        <img v-else src="@/components/assets/to-content.svg" alt="">
                    </div>
                    <ul>
                        <li class="navigation-sections li" v-for="(text, index) in data.secondSection.sectionElements"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                    <div @click="move(3)" class="navigation-section-subheader-container">
                        <h3 class="navigation-section-subheader"
                            :class="currentPosition >= thirdSectionPosition ? 'active-navigation' : ''">{{
                                data.thirdSection.sectionName }}</h3>
                        <img v-if="currentPosition >= thirdSectionPosition" src="@/components/assets/to-content-filled.svg"
                            alt="">
                        <img v-else src="@/components/assets/to-content.svg" alt="">
                    </div>
                    <ul>
                        <li class="navigation-sections li" v-for="(text, index) in data.thirdSection.sectionElements"
                            :key="index + Math.random() * 10000">
                            {{ text }}
                        </li>
                    </ul>
                </div>
                <button class="navigation-btn" @click="move(0)">Back To Top <img class="navigation-img"
                        src="@/components/assets/back-to-top.svg" alt=""></button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['data'],
    data() {
        return {
            currentPosition: window.scrollY,
            firstSectionPosition: 0,
            secondSectionPosition: 0,
            thirdSectionPosition: 0
        }
    },
    methods: {
        checkPosition(e) {
            this.currentPosition = e.currentTarget.scrollY
        },
        navigationPosition(number) {
            switch (number) {
                case 0:
                    return 0
                case 1:
                    return this.firstSectionPosition
                case 2:
                    return this.secondSectionPosition
                case 3:
                    return this.thirdSectionPosition
            }
        },
        move(number) {
            window.scrollTo({
                top: this.navigationPosition(number),
                behavior: "smooth",
            });
        }
    },
    mounted() {
        const firstElement = this.$refs.firstElementRef;
        const secondElement = this.$refs.secondElementRef;
        const thirdElement = this.$refs.thirdElementRef;

        const firstPos = firstElement.getBoundingClientRect();
        const secondPos = secondElement.getBoundingClientRect();
        const thirdPos = thirdElement.getBoundingClientRect();

        this.firstSectionPosition = firstPos.top
        this.secondSectionPosition = secondPos.top
        this.thirdSectionPosition = thirdPos.top
    },
    created() {
        window.addEventListener('scroll', this.checkPosition);
    },
    unmounted() {
        window.removeEventListener('scroll', this.checkPosition);
    },
};
</script>
<style scoped>
h1,
h2,
h3,
p,
button,
input,
label,
li {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}

.dot-container {
    margin-top: 20px;
    margin-bottom: 12px;
}

.privacy-policy-header {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    margin-bottom: 8px;
}

.privacy-policy-last-update {
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    color: #FFFFFF66;
    margin-bottom: 16px;
}

.privacy-policy-date {
    color: #FFFFFF;
}

.logo {
    width: 70px;
    margin: 12px 32px;
}

.privacy-policy-container {
    display: flex;
    justify-content: space-between;
}

.privacy-policy-wrapper {
    margin: 29px 60px;
    width: 60%;
    flex-direction: column;
    height: fit-content;
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    padding: 24px 32px;
    border-radius: 12px;
}

.privacy-policy-navigator-container {
    position: sticky;
    top: 30px;
    height: fit-content;
}

.privacy-policy-navigator {
    margin: 29px 60px 16px 0;
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    padding: 24px 32px;
    border-radius: 12px;
}

.privacy-policy-subscription {
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    margin-bottom: 16px;
}

.privacy-policy-section-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    margin-bottom: 12px;
}

.privacy-policy-section-subheader {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    margin-bottom: 8px;
}

.privacy-policy-default-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    margin-bottom: 12px;
    color: #FFFFFF66;
}

.li {
    margin-bottom: 0;
}

.normal-weight {
    font-weight: 400;
}

.navigation-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    margin-left: 32px;
}

.navigation-img {
    margin-left: 6px;
}

.navigation-section-subheader {
    width: 80%;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    color: #FFFFFF66;
    transition: .2s all;
}

.navigation-section-subheader:hover {
    color: #ffffff9a;
}

.navigation-sections {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
    margin-bottom: 12px;
    color: #FFFFFF66;
}

.navigation-section-subheader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    cursor: pointer;
}

.active-navigation {
    color: white
}

@media only screen and (max-width: 1200px) {

    .privacy-policy-navigator,
    .privacy-policy-wrapper {
        margin: 30px;
    }
}

@media only screen and (max-width: 730px) {
    .privacy-policy-navigator-container {
        display: none;
    }

    .privacy-policy-wrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 375px) {
    .logo {
        margin: 12px 10px;
    }
    .privacy-policy-wrapper {
        margin: 20px 5px;
        padding: 24px 5px;
    }
}</style>
